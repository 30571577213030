$black: #000;
$white: #fff;

$dndred: #da3827;

$color1: #efefef;
$color2: #8ec1a3;
$color3: #3454d1;
$color4: #383838;
$color5: #d1345b;

$blue0: #ebeef7;
$blue1: #bacaff;
$blue2: #3f5aa6;
$blue3: #192a59;
$red: #a63f3f;

$sidebar-bg: #2E3E4D;

$header-highlight: $color2;

$primary-color: $color5;
$secondary-color: $color3;

$bg-color: #EBEEF6;
$border-color: #F0F2F8;

$link-color: $color2;
