@import '../../assets/scss/import.scss';

*,
*:before,
*:after {
	box-sizing: border-box;
}

body {
	font-family: $ff-mont;
	font-size: 16px;
	background-color: $bg-color;
	color: #333;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

h1 {
	background-color: rgba($black, 0.02);
	font-size: 32px;
	padding: 0;
}

a {
	color: currentColor;
	text-decoration: none;
}

.layout {
	display: flex;
	flex-direction: column;
	margin: 0;
	height: 100vh;
	width: 100vw;
}

.navigation {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex: 0 0 58px;
	overflow: hidden;
	font-size: 15px;
	font-weight: 700;
	color: $white;
	// background-color: rgba($dndred, .9);
	background-color: #151b20;
	transition: flex-basis 250ms ease-in;

	&__icon {
		height: 30px;
		width: 30px;
	}

	h1 {
		flex: 0 0 80px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 10px;
	}

	ol {
		flex: 0 0 300px;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		list-style-type: none;
		margin: 0;
		padding: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.035);
	}

	li {
		flex: 0 0 58px;
		height: 58px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		list-style-type: none;
		padding: 0 10px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.035);
	}

	a {
		flex: 1 1 auto;
		text-transform: uppercase;
	}
}


.layout-content {
	position: relative;
	display: flex;
	flex-direction: row;
	height: calc(100vh - 58px);
	width: 100vw;

	&__content {
		width: 100%;
		height: 100%;
		overflow-y: auto;
	}
}
