@import '../../assets/scss/import.scss';

.monsterstats {
	table {}

	th {
		text-align: left;
	}

	td {}

	&__adjust-hp {
		display: inline-block;
		background-color: $dndred;
		border: 0;
		outline: 0;
		padding: 5px;

		text-transform: uppercase;
		font-size: 18px;
		color: $white;

		margin-right: 10px;
		margin-bottom: 10px;

		cursor: pointer;

		&--heal {
			background-color: $color2;
		}

		img {
			display: block;
			width: 40px;
			height: 40px;
		}
	}

	pre {
		white-space: pre-wrap;
		font-family: $ff-mont;
		margin-top: 0;
	}

	iframe {
		border: none;
		margin-top: 20px;
		width: 100%;
		height: calc(100vh - 206px);
	}
}


