@import '../../assets/scss/import.scss';

.monstersearch {
	display: block;
	height: 4em;
	width: 100%;

	background: transparent;
	color: $white;
	border: none;

	font-size: 18px;
	padding-left: 20px;

	outline: none;

	&::placeholder {
		color: rgba($white, .3);
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: $white;
		-webkit-box-shadow: 0 0 0px 1000px $sidebar-bg inset;
		background-color: $sidebar-bg !important;
	}
}
