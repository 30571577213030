.turnmarker {
	position: absolute;
	top: 50%;
	right: 0;

	height: 0;
	width: 0;

	border-style: solid;
	border-width: 10px 7px 10px 0;
	border-color: transparent white transparent transparent;

	transform: translateY(-50%);
}
