@import '../../assets/scss/import.scss';

.trackerlist {
	list-style-type: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin: 0;
}

.trackerlist-item {
	position: relative;

	flex: 0 0 auto;

	display: flex;
	// justify-content: flex-start;
	align-items: center;

	outline: 0;

	text-align: left;
	font-size: 1.05em;
	font-weight: 500;
	color: $white;

	cursor: move;

	&__link {
		$link: & !global;
		position: relative;

		flex: 1 1 auto;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
		height: 75px;
		// padding-top: 10px;

		background-image: linear-gradient(to right, $sidebar-bg, $sidebar-bg, $sidebar-bg, #008bcf, #00cfae);
		background-size: 900px 55px;
		background-position: 0 0;

		cursor: pointer;

		border-top: 1px solid rgba($black, .1);
		border-bottom: 1px solid rgba($black, .1);
		transition: border-color 200ms ease-out, background-position 400ms ease-in-out, height 400ms ease-in-out;

		$dead: null;
		&--dead {
			$dead: &;
			height: 34px;
		}

		&__name {
			$name-select: & !global;

			&--player {
				border-bottom: 1px solid $color2;
			}

			&--monster {
				border-bottom: 1px solid $dndred;

				#{$dead} & {
					margin-left: 20px;
				}
			}
		}

		&__initiative {
			position: absolute;
			top: 5px;
			right: 5px;
			font-size: 14px;
			color: #e4f2ff;

			// transform: translateY(-50%);
			opacity: .1;

			.active & {
				opacity: 0.7;
			}
		}

		&__hp {
			$hp: & !global;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 2px;
			background-color: $dndred;

			transition: width 200ms;

			&__current {
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				width: 100%;
				background: $color2;

				transition: height 200ms;
			}

			&__display {
				$hpDisplay: & !global;
				white-space: nowrap;
				position: absolute;
				top: 5px;
				left: 20px;
				text-align: left;

				font-size: 12px;
				color: #e4f2ff;
				opacity: 0;

				transform: translate3d(-200%, 0, 0);
				transition: transform 200ms, opacity 200ms;

				&--dead {
					opacity: .7;
					transform: translate3d(0, 0, 0);
				}
			}
		}

		&:hover {
			#{$hp} {
				width: 4px;
			}

			#{$hpDisplay} {
				opacity: .2;
				transform: translate3d(0, 0, 0);
			}
		}

		&.active {
			background-position: -600px 0;
			border-top-color: rgba($black, 0);
			border-bottom-color: rgba($black, 0);

			#{$name-select} {
				border-bottom: 1px solid $white;
			}

			#{$hpDisplay} {
				opacity: .7;
				transform: translate3d(0, 0, 0);
			}
		}
	}

	&:hover #{$link} {
		// border-top-color: rgba($black, .1);
		// border-bottom-color: rgba($black, .1);
		&::before {
			content: '';
			position: absolute;
			top: -1px;
			right: 0;
			bottom: -1px;
			left: 0;

			background-color: rgba($black, .08);
		}
	}

	&.dragging {
		cursor: move;
		box-shadow: 0 0 0 1px rgba($black, .1);
	}

	&__actions {
		position: absolute;
		bottom: 50%;
		right: 10px;
		z-index: 1;

		transform: translateY(50%);

		display: flex;
		flex-direction: row;

		&__button {
			display: flex;
			padding: 0;
			border: none;
			background-color: transparent;
			// border-radius: 5px;
			// background: rgba($black, .7);
			// border: 1px solid currentColor;
			color: #008bcf;
			font-family: monospace;
			font-size: 16px;
			font-weight: 700;

			height: 20px;
			width: 20px;

			outline: 0;
			margin-left: 5px;

			cursor: pointer;

			img {
				opacity: 0.4;
				transition: opacity 120ms ease-in;

				&:hover {
					opacity: 0.9;
				}
			}
		}
	}
}
