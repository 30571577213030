@import '../../assets/scss/import.scss';

.encounter-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	button {
		background: rgba($black, .5);
		border: none;
		border-radius: 3px;
		color: $white;
		font-size: 14px;
		font-weight: 700;
		padding: 5px 10px;
		margin-right: 5px;

		outline: 0;
		cursor: pointer;
	}

	&__actions {
		flex: 0 0 100%;
	}

	&__encounter {
		flex: 1 1 300px;
		background-color: $sidebar-bg;
		color: $white;
		border-radius: 3px;
		margin: 10px;
		padding: 5px;

		outline: 0;

		&--selected {
			background-color: #008bcf;
		}

		&__monsters {
			display: block;
			padding: 0 0 0 10px;
			margin: 0;

			li {
				display: block;
				margin: 0;
				padding: 0;
				line-height: 40px;
			}
		}

		&__monster {
			&__button {
				float: right;
			}
		}
	}
}
