@import '../../assets/scss/import.scss';

.sidebarlayout {
	position: relative;
	display: flex;
	flex-direction: row;
	height: 100%;

	&__sidebar {
		flex: 0 0 300px;
		display: flex;
		flex-direction: column;
		background: $sidebar-bg;

		padding-top: 58px;
		max-height: 100%;
		overflow-y: auto;
	}

	&__content {
		width: 100%;
		padding: 20px;

		// box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);

		max-height: 100%;
		overflow-y: auto;
	}
}
