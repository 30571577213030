@import '../../assets/scss/import.scss';

.initiative-tracker {
	flex: 0 0 300px;
	display: flex;
	flex-direction: column;
	background: $sidebar-bg;
	// border-right: 1px solid #333;
}


